.flashsale {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) -2.24%,
    #feb200 -2.23%,
    #ffdc00 97.51%
  );
  padding: 0px 5px 3px 6px;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

a {
  display: inline-block;
}
a svg {
  display: inline-block;
  vertical-align: middle;
}
